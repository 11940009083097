<template>
    <div>
        <div class="p-fluid">
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label for="period">Periode</label>
                    <Calendar selectionMode="range"  v-tooltip.top="'Maksimal 30 hari'" :manualInput="false" v-model="period" :maxDate="maxDate" dateFormat="dd/mm/yy" icon="pi pi-calendar" :showIcon="true"/>
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Area</label>
                    <Dropdown id="area_id" dataKey="area_id" v-model="filters.area_id" :loading="loadingDropdownArea"
                        :options="dataDropdownArea"
                        optionLabel="area_name" optionValue="area_id" placeholder="Pilih Area"
                        :filter="true" :showClear="true" @filter="searchDropdownArea($event, 'filter')" @change="changeRegion()"
                     />
                </div>
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Region</label>
                    <Dropdown id="region_id" dataKey="region_id" v-model="filters.region_id" :loading="loadingDropdownRegion"
                        :options="dataDropdownRegion"
                        optionLabel="region_name" optionValue="region_id" placeholder="Pilih Region" @change="changeBranch()"
                        :filter="true" :showClear="true" @filter="searchDropdownRegion($event, 'filter')"
                     />
                </div>
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Branch</label>
                    <Dropdown id="branch_id" dataKey="branch_id" v-model="filters.branch_id" :loading="loadingDropdownBranch"
                        :options="dataDropdownBranch"
                        optionLabel="branch_name" optionValue="branch_id" placeholder="Pilih Brand" @change="changeSubBranch()"
                        :filter="true" :showClear="true" @filter="searchDropdownBranch($event, 'filter')"
                     />
                </div>
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Sub Branch</label>
                    <Dropdown id="subbranch_id" dataKey="subbranch_id" v-model="filters.subbranch_id" :loading="loadingDropdownSubBranch"
                        :options="dataDropdownSubBranch"
                        optionLabel="subbranch_name" optionValue="subbranch_id" placeholder="Pilih Sub Branch"
                        :filter="true" :showClear="true" @filter="searchDropdownSubBranch($event, 'filter')"
                     />
                </div>
            </div>
        </div>
        <Button :loading="loading" label="Filter" icon="pi pi-search" class="p-button-warning mr-2 my-1" @click="getDataTable" />
    </div>
</template>

<script>

import moment from 'moment';

export default {
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownArea: false,
            loadingDropdownRegion: false,
            loadingDropdownBranch: false,
            loadingDropdownSubBranch: false,

            // dataDropdown
            dataDropdownArea: null,
            dataDropdownRegion: null,
            dataDropdownBranch: null,
            dataDropdownSubBranch: null,

            // filter
            filters: {
                area_id: null,
                region_id: null,
                branch_id: null,
                subbranch_id: null,
            },
            period: [new Date(), new Date()],
            maxDate: null,
            minDate: null,
        }
    },
    created(){
        this.searchDropdownArea('');
    },
    watch: {
        period(){
            //max range 30 days
            this.maxDate =  new Date(this.period[0].getFullYear(), this.period[0].getMonth(), this.period[0].getDate() + 30);
        }
    },
    computed:{
        period_start_label(){ 
            return moment(this.period[0], 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
        period_end_label(){ 
            return moment(this.period[1], 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
    },
    methods: {
        // DROPDOWN
        searchDropdownArea(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.darea.filterValue = valueEdit;
                }

                if (purpose == "filter") {
                    this.loadingDropdownArea = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/area',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                    .then(res => {

                        if (purpose == "filter") {
                            this.dataDropdownArea = res.data.data;
                            this.loadingDropdownArea = false;
                        } else if (purpose == null) {
                            this.dataDropdownArea = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }, 250);
        },
        changeRegion() {
            this.filters.region_id = null;
            this.dataDropdownRegion = null;
            this.searchDropdownRegion('');

            this.changeBranch();
        },
        searchDropdownRegion(event, purpose, valueEdit) {
            setTimeout(() => {

                if(this.filters.area_id){

                    if (valueEdit) {
                        this.$refs.dreg.filterValue = valueEdit;
                    }

                    if (purpose == "filter") {
                        this.loadingDropdownRegion = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/region',
                        params: {
                            "search": valueEdit ? valueEdit : event.value,
                            "area_id" : this.filters.area_id
                        }
                    })
                        .then(res => {

                            if (purpose == "filter") {
                                this.dataDropdownRegion = res.data.data;
                                this.loadingDropdownRegion = false;
                            } else if (purpose == null) {
                                this.dataDropdownRegion = res.data.data;
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            }, 250);
        },
        changeBranch() {
            this.filters.branch_id = null;
            this.dataDropdownBranch = null;
            this.searchDropdownBranch('');

            this.changeSubBranch('');
        },
        searchDropdownBranch(event, purpose, valueEdit) {
            setTimeout(() => {

                if(this.filters.region_id){

                    if (valueEdit) {
                        this.$refs.dbranch.filterValue = valueEdit;
                    }

                    if (purpose == "filter") {
                        this.loadingDropdownBranch = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/branch',
                        params: {
                            "search": valueEdit ? valueEdit : event.value,
                            "region_id" : this.filters.region_id
                        }
                    })
                        .then(res => {

                            if (purpose == "filter") {
                                this.dataDropdownBranch = res.data.data;
                                this.loadingDropdownBranch = false;
                            } else if (purpose == null) {
                                this.dataDropdownBranch = res.data.data;
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            }, 250);
        },
        changeSubBranch() {
            this.filters.subbranch_id = null;
            this.dataDropdownSubBranch = null;
            this.searchDropdownSubBranch('');
        },
        searchDropdownSubBranch(event, purpose, valueEdit) {
            setTimeout(() => {

                if(this.filters.branch_id){

                    if (valueEdit) {
                        this.$refs.dsubbranch.filterValue = valueEdit;
                    }

                    if (purpose == "filter") {
                        this.loadingDropdownSubBranch = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/subbranch',
                        params: {
                            "search": valueEdit ? valueEdit : event.value,
                            "branch_id" : this.filters.branch_id
                        }
                    })
                        .then(res => {

                            if (purpose == "filter") {
                                this.dataDropdownSubBranch = res.data.data;
                                this.loadingDropdownSubBranch = false;
                            } else if (purpose == null) {
                                this.dataDropdownSubBranch = res.data.data;
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            }, 250);
        },
        getDataTable(){
            if(
              this.period_start_label !== null && 
              this.period_end_label !== null && 
              this.period_start_label !== 'Invalid date' && 
              this.period_end_label !== 'Invalid date'
            ){  
                this.$emit('submit');
            }else{
                 alert('Pastikan rentang waktu sudah benar atau tidak kosong');
            }
        }
    }
}
</script>

